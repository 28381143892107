import React,{useReducer} from 'react'
import reducer from './auth-context-reducer'

const initialState = {
    accessToken: '',
    userName: ''
}

export const AuthContextState = React.createContext(initialState)
export const AuthContextDispatch = React.createContext({})

export default props => {
    const [state, dispatch] = useReducer(reducer,initialState)

    return (
        <AuthContextState.Provider value={state}>
            <AuthContextDispatch.Provider value={dispatch}>
                {props.children}
            </AuthContextDispatch.Provider>
        </AuthContextState.Provider>
    )
}