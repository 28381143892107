module.exports = [{
      plugin: require('/usr/app/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"head":true,"anonymize":true,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('/usr/app/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":50,"linkImagesToOriginal":false},
    },{
      plugin: require('/usr/app/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":false,"disableMinification":true},
    },{
      plugin: require('/usr/app/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
