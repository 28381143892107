// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-static-template-js": () => import("/usr/app/src/templates/staticTemplate.js" /* webpackChunkName: "component---src-templates-static-template-js" */),
  "component---src-pages-404-js": () => import("/usr/app/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advanced-search-js": () => import("/usr/app/src/pages/advanced-search.js" /* webpackChunkName: "component---src-pages-advanced-search-js" */),
  "component---src-pages-bejelentes-js": () => import("/usr/app/src/pages/bejelentes.js" /* webpackChunkName: "component---src-pages-bejelentes-js" */),
  "component---src-pages-element-list-js": () => import("/usr/app/src/pages/element_list.js" /* webpackChunkName: "component---src-pages-element-list-js" */),
  "component---src-pages-elements-js": () => import("/usr/app/src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-index-js": () => import("/usr/app/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-visualisation-js": () => import("/usr/app/src/pages/visualisation.js" /* webpackChunkName: "component---src-pages-visualisation-js" */)
}

