import * as actionTypes from '../actionTypes'

const reducer = (state,action) => {
    switch(action.type){
        case actionTypes.SET_ACCESS_TOKEN:                   return {...state, accessToken: action.accessToken, userName: action.userName};
        case actionTypes.REMOVE_ACCESS_TOKEN:                return {...state, accessToken: '', userName: ''}


        default: return state
    }
}

export default reducer