import ApolloClient from 'apollo-boost'
import fetch from 'isomorphic-fetch'
import { accessToken } from '../components/Auth/accessToken';

const client = new ApolloClient({
    uri: process.env.GATSBY_BACKEND_API_URL,
    fetch,
    request: (operation) => {
        if (accessToken !== '') {
            operation.setContext({
                headers: {
                    authorization: `Bearer ${accessToken}`
                }
            })
        }
    }
});

export default client;