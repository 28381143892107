import React from 'react'

import { ApolloProvider } from '@apollo/react-hooks'
import client from './client'

import BasicSearchProvider from '../context/search-context/search-context'
import BasicAuthProvider from '../context/auth-context/auth-context';

export const wrapRootElement = ({ element }) => (
    <BasicAuthProvider>
        <BasicSearchProvider>
            <ApolloProvider client={client}>
                {element}
            </ApolloProvider>
        </BasicSearchProvider>
    </BasicAuthProvider>
)